
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {users, userViews} from '../../resources';
import moment from 'moment';
import {User} from '@/externals/MaxCI-User-v1';
import {ObjectId} from 'bson';
import lodash from 'lodash';

@Component({
  name: 'userList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private userInfoData = {} as any;
  private searchData = {phone: '', name: ''};
  private dialogDel = false;
  private operateId = '';
  private total = 0;
  private list: Array<User> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private applicationId = this.$route.params.applicationId;
  private roleName = '';

  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    if (this.applicationId === localStorage.getItem('applicationId')) {
      this.roleName = '平台管理员';
    } else {
      this.roleName = '应用管理员';
    }
    this.updateList();
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //获取用户头像
  private getAvatar(id: ObjectId) {
    let avatar = '';
    if (
      this.userInfoData[id.toHexString()] &&
      this.userInfoData[id.toHexString()].spec.avatar
    ) {
      avatar = this.userInfoData[id.toHexString()].spec.avatar;
    }
    return avatar;
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await users.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.phone) {
              match(
                f => f('spec')('phone'),
                e => e.$eq(this.searchData.phone),
              );
            }
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      let userIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        userIdData.push(item._id);
      });
      userIdData = Array.from(new Set(userIdData));
      const userData = await userViews.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        ),
      );
      this.userInfoData = lodash.zipObject(
        userData.map(v => v._id.toHexString()),
        userData,
      );

      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
